import '../Styles/NavBar.css';
import {Link} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav id='navbar'>
            <div id='navigation' className='container'>
                <Link to = {'/'}><img src="https://i.postimg.cc/xCsdzjqF/cyplogo.png" alt='logotipo'/></Link>
                <ul id='main-nav'>
                    <li><Link to={'/home'} className='option'>HOME</Link></li>
                    <li><Link to={'/modulos'} className='option'>MODULOS</Link></li>
                    <li><Link to={'/desarrollo'} className='option'>DESARROLLO</Link></li>
                    <li><Link to={'/fotogaleria'} className='option'>FOTOGALERIA</Link></li>
                    <li><Link to={'/contacto'} className='option'>CONTACTO</Link></li>
                </ul>
            </div>
        </nav>
    )
}

export default NavBar;