import React from "react"
import '../Styles/Casas.css'

const Casas = () => {
    return (
        <section>
            <h2 className="categorias">Nuestros Productos</h2>
        <div className="casas">
           
        <div className="esparcimiento">
                <h3>Esparcimiento</h3>
                <img src="https://i.postimg.cc/WpH3p4Bd/moduloesparcimiento.jpg"/>
                <h4>DETALLES</h4>
                <p>Un espacio con posibilidades infinitas,<br/>
                    para la familia, para vos solo, <br/>
                    un lugar donde podras distender, <br/> 
                    explotar tus hobbies, el espacio para <br/>
                    estar con amigos, una sala de reuniones, <br/>
                    o simplemente un espacio de relax.<br/>
                    Nosotros instalamos el espacio, la funcion<br/>
                    la pones vos</p>
                
           </div>

           <div className="habitacional">
                <h3>Vacacional</h3>
                <img src="https://i.postimg.cc/QxKMF3D0/modulohabitacional.jpg"/>
                <h4>DETALLES</h4>
                <p>Si tu intencion es llevar a cabo un <br/>
                emprendimiento hotelero, que brinde <br/>
                una experiencia novedosa, en conexion<br/>
                 con la naturaleza, o bien tener tu propio<br/> 
                 espacio para descansar los fines de semana,<br/> 
                 este espacio es ideal para vos, <br/>
                 replicas de vagon de tren con habitacion, <br/>
                 cocina y baño, ideales para descansar<br/>
                 y vivir una experiencia diferente</p>
           </div>

           <div className="oficina">
                <h3>Recepcion u Oficina</h3>
                <img src="https://i.postimg.cc/nh8h7PcZ/modulooficina.jpg"/>
                <h4>DETALLES</h4>
                <p>Una solucion practica y economica<br/> 
                para la instalacion de oficinas o<br/>
                 ambientes pequeños en tu empresa<br/>
                 de fabricación rapida<br/>
                 e instalcion limpia <br/>
                 facil de ubicar y trasladar</p>
           </div>

        </div>

        <div className='end'>
                <img src="https://i.postimg.cc/xCsdzjqF/cyplogo.png"/><br/>
        </div>
        </section>
    )
}

export default Casas;