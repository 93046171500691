import React from "react"
import '../Styles/Desarrollo.css'

const Desarrollo = () => {
    return (
        <div className="desarrollo">
        <section >
            <h2>Desarrollo</h2>
            <h3>La forma en que construimos nuestras unidades</h3>
            <p>Nuestras construcciones buscan unir lo tradicional y moderno para dar
                lugar a un nuevo espacio inspirado en vagones de tren. La madera y el
                hierro se combinan junto a materiales modernos y reciclados usando una
                construcción tipo en seco. En caso de los módulos vacacionales cuentan
                con cocina, baño y ambientes adaptados a su necesidad como sala de
                estar y dormitorio, con servicios de agua y luz totalmente instalados.
                Los equipos están estructurados en perfilería de hierro tipo Steel Frame y
                revestido exteriormente en madera o placa epoxicas e interiormente por
                fenólico, Placa de yeso o melamina e interiormente con calidad y también
                reciclados de alta performance.
                Los techos están recubiertos en chapa acanalada con cámara de aire y
                aislación en lana de vidrio. El piso puede ser flotante vinilico o cerámico.
                Las aberturas pueden ser tipo motor home, construidas en perfilería de
                aluminio y vidrio corredizo templado o de línea propia.</p>

        </section>

        <div className='end'>
            <img src="https://i.postimg.cc/xCsdzjqF/cyplogo.png"/><br/>
        </div>
    </div>
    )
}

export default Desarrollo;