import React from 'react'
import './App.css';
import NavBar from './Components/NavBar.js';
import Footer from './Components/Footer.js';
import Home from './Components/Home.js';
import Casas from './Components/Casas.js';
import Fotogaleria from './Components/Fotogaleria.js';
import Desarrollo from './Components/Desarrollo.js';
import Contacto from './Components/Contacto.js';

import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
  return (
    <Router>
    <div>
      
    <div className="App">
      <header className="App-header">
        <NavBar/>
      </header>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/home' element={<Home/>}/>
          <Route exact path='/modulos' element={<Casas/>}/>
          <Route exact path='/desarrollo' element={<Desarrollo/>}/>
          <Route exact path='/fotogaleria' element={<Fotogaleria/>}/>
          <Route exact path='/contacto' element={<Contacto/>}/>
        </Routes>
      <footer>
        <Footer/>  
      </footer>   
    </div>
    
    </div>
    </Router>
  );
}

export default App;
