import React, { Component } from 'react';
import '../Styles/Home.css';

const Home = () => {
        return (
                <section id='home'>
                        <div className='banner'>
                        
                        </div>

                        <div className='divisor'>
                                <h1>Modulos Habitacionales</h1>
                        </div>

                        <div className='detailsmenu'>
                                <button>VER CARACTERISTICAS</button>
                        </div>

                        <div className='mision'>
                                <p>Creamos espacios para recreación con formato de vagón de tren<br/> a partir de materiales de
                                primera calidad y materiales reciclados. </p>
                        </div>

                        <div className='bannerimages'>
                                <img src="https://i.postimg.cc/k4M4GRMb/frente.jpg" alt='frente'/>
                                <img src="https://i.postimg.cc/zfr3mP9p/interior.jpg" alt='interior'/>
                                <img src="https://i.postimg.cc/8zWs0Vnj/cocina.jpg" alt='cocina'/>
                                <img src="https://i.postimg.cc/tRS11kxC/banio.jpg" alt='banio'/>
                        </div>

                        <div className='frasemodulos'>
                                <div className='titfrase'><h2>C O N E C T A T E <br/>C O N  L O S <br/>S E N T I D O S</h2></div>
                                <div className='frase'><p>Apostamos por espacios que permitan su uso <br/>
                                        a modo recreacional y/o vacacional.<br/>
                                        Todas las comodidades y el confort, permitiendo <br/>
                                         estar en contacto con la naturaleza</p></div>
                        </div>

                        <div className='details'>
                              <div className='partevacia'></div>
                                <div className='partedetalle'>
                                        <h3>Sistema modular de fabricación</h3>
                                        <ul> - Dimensiones y servicios acorde a tus necesidades</ul>
                                        <ul> - Diferentes opciones en terminaciones.</ul>
                                        <ul> - Fácil de instalar y transportar</ul>
                                        <ul> - Minimiza gastos de mantenimiento</ul>
                                        <ul> - Construcción en seco</ul>
                                </div>  
                        </div>

                        <div className='videoexplicativo'>
                        <p>Para entender nuestro sistema<br/> 
                        de construcción dejamos un video <br/>  
                        explicativo, donde mostramos <br/>
                        las diferentes configuraciones <br/> 
                        que podemos crear</p>
                        <iframe src="https://www.youtube.com/embed/qf8Iv0-f3Pk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" width="560" height="315" frameborder="0"></iframe>
                        </div>

                        <div className='end'>
                                <img src="https://i.postimg.cc/xCsdzjqF/cyplogo.png"/><br/>
                        </div>
                </section>
                
        )
}

export default Home;