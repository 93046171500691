import React from "react"
import '../Styles/Fotogaleria.css'

const Fotogaleria = () => {
    return (
        <div>
            <h2 className="titgaleria">Fotogaleria</h2>
            <div className="galeria">
                <div className="item">
                    <img className="imagen" src='https://i.postimg.cc/x1S9qNt1/Foto1.jpg'/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/cLX1j4B1/Foto2.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/qqs4Gk7T/Foto3.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/wB4vtT14/Foto4.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/PfwxxHTK/Foto6.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/9MvQp8tG/Foto7.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/pTn2pQby/Foto8.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/2yh8XQ2g/Foto5.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/Kv78P5KM/Foto9.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/Fz4Qz2Vn/Foto18.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/6pmJn7hZ/Foto10.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/MGfkDWsF/Foto11.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/7Z6r51SD/Foto12.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/tCbX4VrC/Foto14.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/G3GLRjx5/Foto13.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/tgsbMdgD/Foto15.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/dtJYYc1h/Foto16.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/1RGQ239x/Foto17.jpg"/>
                </div>
                
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/Fz4Qz2Vn/Foto18.jpg"/>
                </div>
                <div className="item">
                    <img className="imagen" src="https://i.postimg.cc/15MZR7nJ/Foto20.jpg"/>
                </div>
            </div>
            <div className='end'>
                <img src="https://i.postimg.cc/xCsdzjqF/cyplogo.png"/><br/>
        </div>
        </div>
    )
}

export default Fotogaleria;