import React from "react";
import '../Styles/Footer.css'

const Footer = () => {
    return (
        <div className='PieDePagina'>
            <p>&copy; Desarrollo Web - Lucas Ponti</p>
        </div>
    )
}

export default Footer;