import React from "react";
import '../Styles/Contacto.css';

const Contacto = () => {
    return (
        <div className="contacto">
            
            <h2>Formulario de contacto</h2>

            <form id="formulario" action="https://formsubmit.co/modulos@cyp-ingenieria.com.ar" method="POST">
                <label for="nombre">Nombre y Apellido<span className="astersico">*</span>:</label>
                <br/>
                <input type="text" id="nombre"  name="Nombre" required></input>
                <br/>
                <label for="email">Email <span className="astersico">*</span>:</label>
                <br/>
                <input type="email" id="email" name="Email" required></input>
                <br/>
                <label for="telefono">Telefono <span className="astersico">*</span>:</label>
                <br/>
                <input type="text" id="telefono" name="Telefono" required></input>
                <br/><br/>
                <label for="comentario">Dejenos su consulta <span className="astersico">*</span>:</label>
                <br/><br/>
                <textarea name="Comentario" id="comentario"  cols="30" rows="10" required></textarea>
                <br/>
                <button  type="submit">ENVIAR CONSULTA</button>

            </form>

            <script src="Functions/sendMail.js"></script>

            <div className='end'>
                <img src="https://i.postimg.cc/xCsdzjqF/cyplogo.png"/><br/>
            </div>
        </div>
    )
}

export default Contacto;